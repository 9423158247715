<template>
  <section class="content">
    <h1>Statuspage</h1>

    <p>Open and close incidents on our public <a href="https://fbinconnectedproducts.statuspage.io">Statuspage</a>.</p>

    <div v-if="loading" class="mt-8" style="width: 600px;">
      <v-progress-linear indeterminate color="primary"></v-progress-linear>
      <div class="text-center mt-3">Loading incidents...</div>
    </div>

    <div v-else>
      <div v-if="incident">
        <v-banner
          single-line
          icon="mdi-alert-circle-outline"
          icon-color="error"
          width="600"
          class="mb-8"
        >An incident is open.</v-banner>

        <v-card width="600">
          <v-card-title class="d-flex justify-space-between align-center">
            <span>{{ incident.name }}</span>
            <v-chip color="blue">{{ incident.status }}</v-chip>
          </v-card-title>
          <v-card-text>
            <p v-html="incident.incident_updates[0].body"></p>
            <p class="mb-0">Created at: {{ formatDate(incident.created_at) }}</p>
          </v-card-text>
          <v-card-actions>
            <v-dialog
              v-model="dialog.close"
              width="500"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="dialog.close = true"
                  class="ml-2 mb-2"
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                >
                  Close Incident
                </v-btn>
              </template>

              <v-card>
                <v-card-title>
                  Close Incident
                </v-card-title>

                <v-card-text>
                  <p>Are you sure you want to close this incident?</p>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-btn
                    color="primary"
                    @click="closeActiveIncident()"
                  >
                    Close Incident
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card-actions>
        </v-card>
      </div>

      <div v-else>
        <v-banner
          single-line
          icon="mdi-check-circle-outline"
          icon-color="success"
          width="600"
          class="mb-8"
        >No incidents are open.  Open one to notify partners of a problem.</v-banner>

        <v-card width="600">
          <v-card-title class="d-flex justify-space-between align-center">
            Open Incident
          </v-card-title>
          <v-card-text>
            <v-form class="form" ref="openForm" @submit.prevent="openAlertConfirm()">
              <div class="d-flex align-baseline">
                <p class="field-name">Title</p>
                <p class="flex-grow-1 mx-4 mb-0">
                  <v-text-field
                    required
                    outlined
                    height="46"
                    dense
                    class="text-field-outlined-overrides"
                    v-model="newIncident.name"
                    :rules="validation.notEmpty"
                  ></v-text-field>
                </p>
              </div>

              <div class="d-flex align-baseline">
                <p class="field-name">Description</p>
                <p class="flex-grow-1 mx-4 mb-0">
                  <v-textarea
                    required
                    outlined
                    dense
                    class="text-field-outlined-overrides"
                    v-model="newIncident.body"
                    :rules="validation.notEmpty"
                  ></v-textarea>
                </p>
              </div>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-dialog
              v-model="dialog.open"
              width="500"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="dialog.open = true"
                  class="ml-2 mb-2"
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                >
                  Open Incident
                </v-btn>
              </template>

              <v-card>
                <v-card-title class="">
                  Open Incident
                </v-card-title>

                <v-card-text>
                  <p>Are you sure you want to open an incident?  This will send notifications to partners.</p>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-btn
                    color="red"
                    dark
                    elevation="0"
                    @click="createActiveIncident()"
                  >
                    Open Incident
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card-actions>
        </v-card>
      </div>
    </div>
  </section>
</template>

<script>
import config from '../config';
import util from '../util';

export default {
  data: () => {
    return {
      validation: config.formValidations,
      loading: true,
      incident: null,
      newIncident: {
        name: 'Potential Service Interruption',
        body: 'We have noticed a potential service interruption that we\'re actively investigating.',
      },
      closeIncident: {
        body: 'We have resolved the issue.  Contact your integration manager for more information.',
      },
      dialog: {
        open: false,
        close: false,
      },
    };
  },
  created: async function () {
    await this.getActiveIncident();
  },
  methods: {
    getActiveIncident: async function () {
      const options = {
        method: 'GET',
        ...config.defaultFetchOptions,
      };
      const result = await util.fetch(`${this.$store.state.apiHost}/statuspage/incident`, options);

      if (result.ok) {
        const incident = await result.json();
        this.incident = incident;
      } else if (result.status === 404) {
        this.incident = null;
      } else {
        this.$store.commit('snackbar', {text: 'Failed to get incident.', color: 'error'});
      }

      this.loading = false;
    },
    createActiveIncident: async function () {
      const formData = new FormData();
      formData.append('name', this.newIncident.name);
      formData.append('body', this.newIncident.body);
      const options = {
        method: 'POST',
        body: formData,
        ...config.defaultFetchOptions,
      };
      const result = await util.fetch(`${this.$store.state.apiHost}/statuspage/incident`, options);

      if (result.ok) {
        this.$store.commit('snackbar', {text: 'Incident opened', color: 'success'});
        this.incident = await result.json();
      } else {
        this.$store.commit('snackbar', {text: 'Failed to open incident', color: 'error'});
      }

      this.dialog.open = false;
    },
    closeActiveIncident: async function () {
      const formData = new FormData();
      formData.append('body', this.closeIncident.body);
      const options = {
        method: 'PUT',
        body: formData,
        ...config.defaultFetchOptions,
      };
      const result = await util.fetch(`${this.$store.state.apiHost}/statuspage/incident`, options);

      if (result.ok) {
        this.$store.commit('snackbar', {text: 'Incident closed', color: 'success'});
        this.incident = null;
      } else {
        this.$store.commit('snackbar', {text: 'Failed to close incident', color: 'error'});
      }

      this.dialog.close = false;
    },
    formatDate: function (dateString) {
      const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };
      return new Date(dateString).toLocaleDateString(undefined, options);
    },
  },
};
</script>