import { render, staticRenderFns } from "./IndexView.vue?vue&type=template&id=68f61e09"
var script = {}
import style0 from "./IndexView.vue?vue&type=style&index=0&id=68f61e09&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports