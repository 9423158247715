<template>
  <div class="credential">
    <div class="d-flex justify-space-between pt-4 px-4 pb-2">
      <h3 class="mb-0">{{credential.fields.name.value}}</h3>

      <v-spacer></v-spacer>
      <v-chip
        v-if="isDisabled(credential)"
        :color="disabledChip.color"
        label
        class="mr-3"
      >{{ disabledChip.text }}</v-chip>

      <v-chip
        v-if="willExpire(credential) || isExpired(credential)"
        :color="expirationChip.color"
        label
      >{{ expirationChip.text }}</v-chip>
    </div>

    <v-form class="form" ref="form">
      <v-tabs
        v-model="tab"
        background-color="grey lighten-4"
        color="black"
      >
        <v-tab key="details">
          Details
        </v-tab>
        <v-tab key="scopes">
          Scopes
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab" class="pb-4 px-4">
        <v-tab-item key="details" class="pt-8">
          <div v-for="(field, index2) in Object.keys(credential.fields)" :key="index2">
            <div class="d-flex align-start" v-if="credential.fields[field].display">
              <p class="credential-name">{{credential.fields[field].label}}</p>
              <div class="flex-grow-1 mx-4 mb-1">
                <v-text-field
                  v-if="['text', 'number'].includes(credential.fields[field].type)"
                  :id="'credential-value-' + index1 + '-' + index2"
                  required
                  outlined
                  height="46"
                  dense
                  class="text-field-outlined-overrides"
                  append-icon="mdi-content-copy"
                  @click:append="clickCopy(credential.fields[field].value)"
                  :hint="getHint(credential.fields[field])"
                  v-model="credential.fields[field].value"
                  :readonly="!credential.fields[field].editing"
                  :rules="getRules(credential.fields[field])"
                ></v-text-field>

                <v-textarea
                  v-if="credential.fields[field].type === 'array' && credential.fields[field].value"
                  :id="'credential-value-' + index1 + '-' + index2"
                  required
                  outlined
                  dense
                  class="text-field-outlined-overrides"
                  append-icon="mdi-content-copy"
                  @click:append="clickCopy(self()[field])"
                  v-model="self()[field]"
                  :readonly="!credential.fields[field].editing"
                  :rules="getRules(credential.fields[field])"
                ></v-textarea>

              </div>
              <div class="credential-edit" v-if="editable">
                <v-btn
                  v-if="credential.fields[field].editable && !credential.fields[field].editing"
                  @click="enableEditing(credential.fields[field], index1, index2)"
                  outlined
                  height="46"
                  width="100%"
                ><v-icon small class="mr-2">mdi-pencil</v-icon>Edit</v-btn>

                <v-btn
                  v-if="credential.fields[field].editable && credential.fields[field].editing"
                  @click="disableEditing(credential.fields[field])"
                  color="primary"
                  height="46"
                  width="100%"
                >Done</v-btn>
              </div>
            </div>
          </div>

          <div>
            <div class="d-flex align-start">
              <p class="credential-name">Disabled</p>
              <div class="flex-grow-1 mx-4 my-2 d-flex align-center">
                <v-switch
                  v-model="credential.fields.disabled.value"
                  @change="updateCredential(credential)"
                  inset
                  class="mt-0 ml-1"
                  hide-details
                  :disabled="$store.state.role !== constants.DeveloperRoles.GLOBAL_ADMIN"
                ></v-switch>
                <span v-if="isDisabled(credential) && willExpire(credential) && !isExpired(credential)">Disabled credentials don't work, even if they're not expired.</span>
                <span v-if="!isDisabled(credential) && isExpired(credential)">Credentials that aren't disabled still work, even if they're expired.</span>
              </div>
            </div>
          </div>
        </v-tab-item>

        <v-tab-item key="scopes">
          <p
            v-if="$store.state.role === constants.DeveloperRoles.GLOBAL_ADMIN"
            class="mt-4"
          >
            Scopes determine what API endpoints a partner can call using this credential.
          </p>
          <p
            v-else
            class="mt-4"
          >
            Scopes determine what API endpoints you can call using this credential.  If you would like an additional scope, talk to your partner integration manager.
          </p>

          <section id="scopes">
            <v-list
              v-for="scopeGroup in Object.keys(scopeGroups)"
              :key="scopeGroup"
              class="scope-list"
            >
              <v-subheader class="scope-header">{{ scopeGroup }}</v-subheader>

              <div
                v-for="(item, i) in scopeGroups[scopeGroup]"
                :key="i"
                :value="item.scope"
              >
                <CredentialScopeArray
                  v-if="item.companionType === 'array'"
                  v-bind:scope="item"
                  v-bind:credential="credential"
                  v-on:scopeSaved="scopeSaved"
                  v-on:scopeChanged="scopeChanged"
                ></CredentialScopeArray>

                <CredentialScopeBoolean
                  v-if="!item.companionType"
                  v-bind:scope="item"
                  v-bind:credential="credential"
                  v-on:scopeSaved="scopeSaved"
                ></CredentialScopeBoolean>
              </div>
            </v-list>
          </section>
        </v-tab-item>
      </v-tabs-items>
    </v-form>

    <div
      v-if="shouldShowExtendButton(credential)"
    >
      <v-divider class="mt-2 mb-4"></v-divider>
      <div class="d-flex ma-4 mt-0">
        <v-icon
          class="mr-4"
          size="x-large"
          color="info"
        >mdi-clock-plus-outline</v-icon>
        <p class="ma-0 flex-grow-1 d-flex align-center">Extend the life of this credential by 30 days.</p>
        <v-btn
          color="primary"
          @click="extendCredentialLife(credential)"
        >
          Extend life
        </v-btn>
      </div>
    </div>

    <div class="pa-4 pt-0" v-if="shouldShowSaveDeleteButtons()">
      <v-btn
        color="primary"
        width="187"
        height="48"
        class="mr-4"
        @click="updateCredential(credential)"
      >Save changes</v-btn>

      <v-tooltip
        v-if="numCredentials === 1"
        bottom
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            text
            width="187"
            height="48"
            v-bind="attrs"
            v-on="on"
          >
            Delete Credential
          </v-btn>
        </template>
        <span>You cannot delete your last credential.</span>
      </v-tooltip>

      <v-btn
        v-else
        text
        width="187"
        height="48"
        @click="deleteCredential(credential)"
      >Delete credential</v-btn>
    </div>

    <v-dialog
      v-model="dialogs.delete"
      width="600"
      height="300"
    >
      <v-card class="delete-partner">
        <v-card-title class="pa-8 justify-center">
          <v-icon class="black--text">mdi-alert-circle</v-icon>
        </v-card-title>
        <v-card-text
          class="pb-8 grey--text text--darken-4"
        >
          <p class="mb-8">Are you sure you want to remove the credential <strong>{{credential.fields.name.value}}</strong>?</p>

          <ul class="mb-8">
            <li>Applications using this credential will not be able to access the API or SDK.</li>
          </ul>

          <v-btn
            width="187"
            height="48"
            class="mr-5"
            dark
            @click="deleteCredentialConfirm(credential)"
          >Delete credential</v-btn>
          <v-btn
            width="187"
            height="48"
            outlined
            @click="dialogs.delete = false"
          >Cancel</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import _ from 'lodash';
import humanizeDuration from 'humanize-duration';

import constants from 'august-constants';

import config from '../config';
import util from '../util';

import CredentialScopeArray from '../components/CredentialScopeArray.vue';
import CredentialScopeBoolean from '../components/CredentialScopeBoolean.vue';

export default {
  name: 'CredentialComponent',
  props: [
    'initialCredential',
    'index1',
    'numCredentials',
    'editable',
    'scopes',
  ],
  components: {
    CredentialScopeArray,
    CredentialScopeBoolean,
  },
  data() {
    return {
      constants,
      credential: this.initialCredential,
      dialogs: {
        delete: false,
      },
      tab: null,
    };
  },
  computed: {
    scopeGroups: function () {
      return _.groupBy(this.scopes, item => item.group);
    },

    // The redirect URIs are stored as an array, but displayed as a string.
    redirectUris: {
      get() {
        return this.credential.fields['redirectUris'].value.join('\n');
      },
      set(value) {
        this.credential.fields['redirectUris'].value = value.split('\n');
      },
    },

    timeUntilExpiration: function () {
      const expiresInMS = this.credential.fields.disableAt?.value - Date.now();

      return humanizeDuration(expiresInMS, {
        units: ['d'],
        round: true,
      });
    },

    expirationChip: function () {
      const now = Date.now();

      if (!this.credential.fields.disableAt.value) {
        return {
          color: 'success',
          text: 'Never expires',
        }
      } else if (this.credential.fields.disableAt.value > now) {
        return {
          color: 'warning',
          text: `Expires in ${this.timeUntilExpiration}`,
        }
      }

      return {
        color: 'error',
        text: 'Expired',
      }
    },

    disabledChip: function () {
      if (this.credential.fields.disabled.value) {
        return {
          color: 'error',
          text: 'Disabled',
        }
      }

      return {
        color: 'success',
        text: 'Enabled',
      }
    },
  },
  methods: {
    self() {
      return this;
    },
    clickCopy: util.clickCopy,
    isDisabled: function (credential) {
      return credential.fields.disabled.value;
    },
    isExpired: function (credential) {
      return credential.fields.disableAt.value < Date.now();
    },
    willExpire: function (credential) {
      return credential.fields.disableAt.value && credential.fields.disableAt.value > Date.now();
    },
    enableEditing: function (credentialProperty, index1, index2) {
      credentialProperty.editing = true;

      const input = document.querySelector(`#credential-value-${index1}-${index2}`);
      if (input) {
        setTimeout(() => input.focus());
      }
    },
    disableEditing: function (credentialProperty) {
      credentialProperty.editing = false;
    },
    disableAllEditing: function () {
      for (const key of Object.keys(this.credential.fields)) {
        this.credential.fields[key].editing = false;
      }
    },
    updateCredential: async function (credential) {
      const isValid = this.$refs.form.validate();
      if (!isValid) {
        this.$store.commit('snackbar', {text: 'Failed to save credential, invalid form.', color: 'error'});
        return;
      }

      const data = {}
      for (const [credentialName, credentialObj] of Object.entries(credential.fields)) {
        if (credentialObj.editable) {
          if (credentialName === 'customTokenTTLDays' && !credentialObj.value) {
            // If empty default to 120 day standard. (Using 0 unsets the field in the DB)
            data[credentialName] = 0;
          }
          else {
            data[credentialName] = credentialObj.value;
          }

        }
      }

      try {
        await util.axios({
          url: `${this.$store.state.apiHost}/credentials/${credential.fields.clientID.value}`,
          method: 'put',
          data,
        });

        this.$store.commit('snackbar', {text: 'Credential saved', color: 'success'});
        this.disableAllEditing();
      } catch (error) {
        this.$store.commit('snackbar', {text: 'Failed to save credential', color: 'error'});
      }
    },
    deleteCredential: function () {
      this.dialogs.delete = true;
    },
    deleteCredentialConfirm: async function (credential) {
      const options = {
        method: 'DELETE',
        ...config.defaultFetchOptions,
      };
      const result = await util.fetch(`${this.$store.state.apiHost}/credentials/${credential.fields.clientID.value}`, options);

      if (result.ok) {
        this.$store.commit('snackbar', {text: 'Credential deleted', color: 'success'});
        this.dialogs.delete = false;
        this.$emit('credential-deleted', credential);
      } else {
        const error = await result.json();
        if (result.status === 409) {
          this.$store.commit('snackbar', {text: error.message, color: 'error'});
        } else {
          this.$store.commit('snackbar', {text: 'Failed to delete credential', color: 'error'});
        }
      }
    },
    extendCredentialLife: async function (credential) {
      try {
        const result = await util.axios({
          url: `${this.$store.state.apiHost}/credentials/${credential.fields.clientID.value}/extend`,
          method: 'post',
        });
        const newTimestamp = result.data.timestamp;
        credential.fields.disableAt.value = newTimestamp;
        credential.fields.disableAtPretty.value = new Date(newTimestamp);
        credential.fields.disabled.value = false;

        this.$store.commit('snackbar', {text: 'Credential life extended', color: 'success'});
      } catch (error) {
        this.$store.commit('snackbar', {text: 'Failed to extend credential life', color: 'error'});
      }
    },
    shouldShowExtendButton (credential) {
      return this.$store.state.role === constants.DeveloperRoles.GLOBAL_ADMIN && credential.fields.disableAt?.value;
    },
    scopeChanged: function (credential, scopeName, scopeType, scopeValue) {
      this.credential.scopes[scopeName] = scopeValue;
    },
    scopeSaved: async function (credential, scopeName, scopeType, scopeValue) {
      this.scopeChanged(credential, scopeName, scopeType, scopeValue);

      try {
        const data = {
          scopeName: scopeName,
          scopeType,
          scopeValue: credential.scopes[scopeName],
        };

        await util.axios({
          url: `${this.$store.state.apiHost}/credentials/${credential.fields.clientID.value}/scope`,
          method: 'put',
          data,
        });

        this.$store.commit('snackbar', {text: 'Scope updated', color: 'success'});
        credential.dialogVisible = false;
      } catch (error) {
        this.$store.commit('snackbar', {text: 'Failed to update scope', color: 'error'});
      }
    },
    shouldShowSaveDeleteButtons: function () {
      return this.editable && // The parent component says the credential is editable.
             this.tab === 0;  // The user is on the credential's 'details' tab.
    },
    getHint: function (field) {
      const hints = {
        'Custom Token TTL (Days)' : 'If left empty, the default is 120 days.'
      }

      return hints[field?.label];
    },
    getRules: function(field) {
      const rules = {
        'Custom Token TTL (Days)' : [v => ( !v || (/^[0-9]+$/.test(v) && v < 120 && v > 0)) || 'Must be a whole number less than 120']
      }

      return rules[field?.label] ? rules[field?.label] : config.formValidations.notEmpty;
    }
  },
};
</script>

<style lang="scss">
.credential {
  .credential-name {
    width: 7rem;
    font-weight: bold;
    margin: 12px 0;
  }
  .credential-edit {
    width: 100px;
  }

  #scopes {
    columns: 2;
    column-gap: 2rem;

    .scope-list {
      width: 300px;
      break-inside: avoid;

      .scope-header {
        padding: 0;
        // border-top: 1px solid #ccc;
        text-transform: uppercase;
      }

      .scope {
        margin: 0 0 0.25rem 0.75rem;
        padding: 0 0 0.75rem 0;
        border-bottom: 1px solid #e0e0e0;

        .scope-title {
          margin-bottom: 0.25rem;
          font-variant: small-caps;
        }
        .scope-subtitle {
          white-space: normal;
        }
      }
    }
  }
}
</style>
